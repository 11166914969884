import React from 'react';
import { PlasmicRootProvider } from '@plasmicapp/react-web';
import { pageView } from './src/util/Analytics.ts';

export const wrapRootElement = ({ element }) => {
  return <PlasmicRootProvider>{element}</PlasmicRootProvider>;
};

// TODO: for page view events at different urls
export const onRouteUpdate = ({ location }) => {
  // pageView({ path: location.pathname });
};
